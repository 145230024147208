<template>
    <div class="scopeServices_page">
        <Banner :adCategory="2"></Banner>
        <div class="breadcrumb_box">
            <Breadcrumb :breadcrumbList="breadcrumbList"></Breadcrumb>
        </div>
        <div class="module1">
            <div class="content">
                <div class="title display">{{ formData.questionName }}</div>
                <div class="solve_problem flex">
                    <div class="solve_problem_list display column" v-for="(item, index) in formData.serviceQuestionVOS"
                        :key="index">
                        <img class="icon_y" :src="item.icon" alt="" />
                        <img class="icon_n" :src="item.highlightIcon" alt="" />
                        <div class="name">{{ item.questionName }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module2">
            <div class="main">
                <div class="title display">{{ formData.harmName }}</div>
                <div class="el_carouse_box">
                    <el-carousel :autoplay="false">
                        <el-carousel-item class="el-car-item" v-for="(list, index) in formData.serviceHarmVOS"
                            :key="index">
                            <div class="carouselData flex-center column" v-for="(imgList, index2) in list"
                                :key="index2">
                                <img class="icon_img" :src="imgList.harmImgUrl" :alt="imgList.harmName" />
                                <div class="name">{{ imgList.harmName }}</div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
        <div class="module3">
            <div class="content">
                <div class="title display">{{ formData.scenesName }}</div>
                <div class="slideshow">
                    <el-carousel :autoplay="false">
                        <el-carousel-item class="el-car-item flex flex-wrap"
                            v-for="(list, index) in formData.serviceApplicationFieldVOS" :key="index">
                            <div class="carouselData flex-center column" v-for="(imgList, index2) in list"
                                :key="index2">
                                <img class="certificate" :src="imgList.harmImgUrl" alt="" />
                                <div class="name display">
                                    {{ imgList.applicationFieldName }}
                                </div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
        <div class="module4 module3">
            <div class="content">
                <div class="title display">{{ formData.intractableDiseasesName }}</div>
                <div class="navBar_box">
                    <NavBar :navBarList="formData.serviceIntractableDiseasesCategoryVOS" @changeNavBar="changeNavBar">
                    </NavBar>
                </div>
                <div class="slideshow">
                    <el-carousel :autoplay="false">
                        <el-carousel-item class="el-car-item flex flex-wrap" v-for="(list, index) in waterproofList"
                            :key="index">
                            <div class="carouselData flex-center column" v-for="(imgList, index2) in list"
                                :key="index2">
                                <img class="certificate" :src="imgList.harmImgUrl" alt="" />
                                <div class="name display">
                                    {{ imgList.intractableDiseasesName }}
                                </div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
        <div class="module5">
            <div class="content">
                <HomeTitle :titleName="formData.packageGoodsName"></HomeTitle>
                <div class="product_main flex">
                    <div class="product_fl">
                        <div class="environmental">
                            <div class="protective">{{ formData.goodsCategoryTitle }}</div>
                            <div class="green_environmental">
                                {{ formData.goodsCategoryDescribe }}
                            </div>
                        </div>
                        <div class="classification">
                            <div class="classification_list" v-for="(item, index) in formData.serviceGoodsCategoryVOS"
                                :key="index" @click="changeBtn(item, index)">
                                <div class="name pointer">{{ item.categoryName }}</div>
                                <div class="line" v-if="classificationIndex == index"></div>
                            </div>
                        </div>
                    </div>
                    <div class="product_fr">
                        <img @click="toGoods" class="product_img pointer" :src="classificationData.marketingImage"
                            alt="" />
                        <div class="up_down">
                            <div class="mask_layer"></div>
                            <div class="cont Between">
                                <img class="pointer" @click="upDown(0)" src="@/assets/img/homes/fl.png" alt="" />
                                <div class="name">
                                    {{ classificationData.classificationName }}
                                </div>
                                <img class="pointer" @click="upDown(1)" src="@/assets/img/homes/fr.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module6">
            <div class="content">
                <div class="title display">{{ formData.featuresName }}</div>
                <div class="characteristic flex flex-wrap">
                    <div class="characteristic_list flex" v-for="(item, index) in formData.serviceFeaturesVOS"
                        :key="index">
                        <img class="icon_img icon_n" :src="item.icon" alt="" />
                        <img class="icon_img icon_y" :src="item.highlightIcon" alt="" />
                        <div class="info">
                            <div class="title_name">{{ item.featuresName }}</div>
                            <div class="details">
                                {{ item.featuresIntroduction }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module7">
            <div class="content">
                <div class="title display">{{ formData.coreAdvantagesName }}</div>
                <div class="advantage flex">
                    <div class="advantage_List" v-for="(item, index) in formData.serviceCoreAdvantagesVOS" :key="index">
                        <img class="icon_img" :src="item.coreAdvantagesImgUrl" alt="" />
                        <div class="name display">{{ item.coreAdvantagesName }}</div>
                        <div class="line"></div>
                        <div class="float_content">
                            <div class="float_content_name display">
                                {{ item.coreAdvantagesName }}
                            </div>
                            <div class="float_content_line"></div>
                            <div class="float_content_details">
                                {{ item.coreAdvantagesIntroduction }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module8">
            <div class="content">
                <div class="title display">{{ formData.constructionSiteName }}</div>
                <div class="el_carousel_box">
                    <el-carousel :interval="5000" arrow="always">
                        <el-carousel-item v-for="(item, index) in formData.imgUrls" :key="index">
                            <div class="el_carousel_cont display">
                                <img class="pointer" :src="item" alt="" />
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
        <div class="module9">
            <div class="content">
                <div class="title">
                    咨询{{ type }}{{ type == '装饰装修' ? '' : '维修' }}报价
                </div>
                <div class="formData display column">
                    <div class="list_message flex">
                        <div class="list">
                            <input type="text" v-model="makeAppointment.userName" placeholder="请输入姓名" />
                        </div>
                        <div class="list">
                            <input type="text" maxlength="11" placeholder="请输入手机号"
                                v-model="makeAppointment.phoneNumber" />
                        </div>
                    </div>
                    <!-- <div class="list_message flex">
                        <div class="list">
                            <el-cascader size="large" :options="options" :props="props" v-model="AreaArray"
                                @change="selectArea" placeholder="请选择所在城市" clearable></el-cascader>
                        </div>
                        <div class="list">
                            <el-select v-model="makeAppointment.categoryName" clearable placeholder="请选择需求类型"
                                @change="selectType">
                                <el-option v-for="item in $store.state.serviceType" :key="item.value" :label="item.label"
                                    :value="item">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <textarea placeholder="请填写详细需求（最多输入200字）" v-model="makeAppointment.detailedRequirements" maxlength="200"
                        name="" id="" cols="10" rows="5"></textarea> -->
                    <div class="btn display pointer" @click="signUp">立即预约</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Banner from '@/components/banner'
import NavBar from '@/components/navBar'
import Breadcrumb from '@/components/breadcrumb'
import areaJSON from '@/assets/js/area.json'
import HomeTitle from '@/components/homeTItle'
export default {
    components: {
        Banner,
        NavBar,
        Breadcrumb,
        HomeTitle,
    },
    data() {
        return {
            type: '',
            navBarIndex: 0,
            classificationIndex: 0,
            classificationData: {},
            value: '',
            options: areaJSON,
            AreaArray: [],
            props: {
                children: 'children',
                label: 'name',
                value: 'name',
            },
            breadcrumbList: [
                {
                    name: '服务范围',
                    url: '',
                },
            ],

            advantageList: [],
            harmList: [],
            waterproofList: [],
            characteristicList: [],
            constructionSiteList: [],
            navBarList: [],
            formData: {},
            makeAppointment: {
                serviceAreaCategoryId: 0,
                categoryName: '-',
                userName: '',
                phoneNumber: '',
                companyName: '无',
                province: '-',
                city: '-',
                district: '-',
                detailAddress: 0,
                detailedRequirements: '-',
                navBarData: {}
            },
        }
    },
    watch: {
        '$store.state.typeId'() {
            this.inits()
        },
    },
    created() {

    },
    mounted() {
        this.inits()
    },
    methods: {
        inits() {
            this.type = this.$route.query.name
            let navBarData = JSON.parse(localStorage.getItem('navBarData'))
            this.breadcrumbList = [
                {
                    name: '服务范围',
                    url: '',
                },
                {
                    name: navBarData.name,
                    url: '',
                },
            ]
            if (navBarData.id) {
                this.getData(navBarData.id)
            }

            this.makeAppointment.serviceAreaCategoryId = navBarData.id
            this.navBarData = navBarData
        },
        getData(id) {
            let params = {
                serviceAreaCategoryId: id,
            }
            this.api.findServiceArea(params).then((res) => {
                this.formData = res.data || {}
                if (this.formData.serviceHarmVOS != undefined) {
                    this.formData.serviceHarmVOS = this.init(
                        this.formData.serviceHarmVOS,
                        4
                    )
                }
                if (this.formData.serviceIntractableDiseasesCategoryVOS != undefined) {
                    this.formData.serviceIntractableDiseasesCategoryVOS.forEach(
                        (item) => {
                            item.name = item.categoryName
                        }
                    )
                    this.waterproofList =
                        this.formData.serviceIntractableDiseasesCategoryVOS[0].serviceIntractableDiseasesVOS
                    this.waterproofList = this.init(this.waterproofList, 6)
                    this.formData.serviceApplicationFieldVOS = this.init(
                        this.formData.serviceApplicationFieldVOS,
                        6
                    )
                }
                this.classification = this.formData.serviceGoodsCategoryVOS
                this.getGoodsClass(
                    this.formData.serviceGoodsCategoryVOS[0].goodsCategoryId
                )
            })
        },
        getGoodsClass(id) {
            this.api.getGoodsClassificationInfo({ id: id }).then((res) => {
                this.classificationData = res.data
            })
        },
        //轮播图初始化
        init(dataList, num) {
            let newDataList = []
            let current = 0
            if (dataList && dataList.length > 0) {
                for (let i = 0; i <= dataList.length - 1; i++) {
                    if (i % num !== 0 || i === 0) {
                        if (!newDataList[current]) {
                            newDataList.push([dataList[i]])
                        } else {
                            newDataList[current].push(dataList[i])
                        }
                    } else {
                        current++
                        newDataList.push([dataList[i]])
                    }
                }
            }
            return (dataList = [...newDataList])
        },
        selectArea(val) {
            this.makeAppointment.province = val[0]
            this.makeAppointment.city = val[1]
            this.makeAppointment.district = val[2]
        },
        //选择服务呢类型分类
        selectType(val) {
            console.log(val)
            this.makeAppointment.categoryName = val.label
            this.makeAppointment.serviceAreaCategoryId = val.value
        },
        changeNavBar(item) {
            console.log(item.serviceIntractableDiseasesVOS)
            this.waterproofList = this.init(item.serviceIntractableDiseasesVOS, 6)
        },
        // 立即报名
        signUp() {
            // if (this.makeAppointment.companyName == '') {
            //     return this.$message.error('请输入公司名称')
            // }
            // if (this.makeAppointment.province == '') {
            //     return this.$message.error('请选择所在城市')
            // }
            // if (this.makeAppointment.categoryName == '') {
            //     return this.$message.error('请选择需求类型')
            // }
            if (this.makeAppointment.userName == '') {
                return this.$message.error('请输入姓名')
            }
            if (!/^[0-9]{11}$/.test(this.makeAppointment.phoneNumber)) {
                return this.$message.error('请正确输入手机号')
            }
            this.api.addReserve(this.makeAppointment).then((res) => {
                if (res.code == 0) {
                    this.$message.success('您的留言已成功提交！')
                    this.makeAppointment = {
                        serviceAreaCategoryId: this.navBarData.id,
                        categoryName: '-',
                        userName: '',
                        phoneNumber: '',
                        companyName: '无',
                        province: '-',
                        city: '-',
                        district: '-',
                        detailAddress: 0,
                        detailedRequirements: '-',
                    }
                    this.AreaArray = []
                }
            })
        },
        //点击配套产品
        changeBtn(item, index) {
            console.log(item)
            this.classificationIndex = index
            this.getGoodsClass(item.goodsCategoryId)
        },
        //点击配套产品-上一个下一个
        upDown(num) {
            if (num == 0) {
                if (this.classificationIndex == 0) {
                    this.classificationIndex = 0
                } else {
                    this.classificationIndex--
                }
            } else {
                let nums = this.classification.length - 1
                if (this.classificationIndex == Number(nums)) {
                    this.classificationIndex = Number(num)
                } else {
                    this.classificationIndex++
                }
            }
            this.getGoodsClass(
                this.classification[this.classificationIndex].goodsCategoryId
            )
        },
        //点击跳转产品中心
        toGoods() {
            localStorage.setItem('isService', 1)
            let routeData = this.$router.resolve({
                path: '/productCenter',
                query: {
                    ids: 5,
                    id: this.classificationData.id,
                    name: this.classificationData.classificationName,
                    index: this.classificationIndex,
                },
            })

            window.open(routeData.href, '_blank')
        },
    },
}
</script>

<style lang="scss" scoped>
.scopeServices_page {
    .title {
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 32px;
        padding-top: 100px;
    }

    .breadcrumb_box {
        width: 100%;
        background: #f4f5f5;
    }

    .module1 {
        height: 472px;
        background: #fff;

        .solve_problem {
            margin-top: 40px;

            .solve_problem_list:nth-child(1) {
                margin-left: 0px !important;
            }

            .solve_problem_list {
                width: 228px;
                height: 228px;
                border: 1px solid rgba(222, 222, 222, 0.96);
                margin-left: 15px;

                img {
                    width: 68px;
                    height: 68px;
                }

                .icon_y {
                    display: block;
                }

                .icon_n {
                    display: none;
                }

                .name {
                    font-size: 20px;
                    font-weight: bold;
                    color: #333333;
                    margin-top: 38px;
                }
            }

            .solve_problem_list:hover {
                background: #e52e36;

                .icon_n {
                    display: block;
                }

                .icon_y {
                    display: none;
                }

                .name {
                    color: #ffffff !important;
                }
            }
        }
    }

    .module2 {
        height: 634px;
        background: #f4f5f5;

        .main {
            width: 1400px !important;
            height: 400px;
            margin: 0 auto;

            .el_carouse_box {
                margin-top: 40px;
            }

            ::v-deep.el-car-item {
                width: 100%;
                display: flex;
                align-items: center;
                padding-left: 106px;
            }

            .carouselData {
                width: 280px;
                height: 360px;
                background: white;
                box-shadow: 0px 1px 5px 0px rgba(64, 64, 64, 0.25);
                margin-right: 20px;
                padding: 5px;
                box-sizing: border-box;
                cursor: pointer;

                .icon_img {
                    width: 270px;
                    height: 220px;
                }

                .name::-webkit-scrollbar {
                    display: none;
                }

                .name {
                    width: 180px;
                    height: 48px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    line-height: 22px;
                    text-align: center;
                    margin-top: 45px;
                    overflow: hidden;
                    overflow-y: scroll;
                }
            }

            .carouselData:last-child {
                margin-right: 0px;
            }

            ::v-deep.el-carousel {
                .el-carousel__container {
                    height: 370px;

                    .el-carousel__arrow {
                        width: 60px;
                        height: 60px;
                        font-size: 24px;
                        background: rgba(51, 51, 51, 0.5);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            ::v-deep.el-carousel__indicators {
                display: none;
            }
        }
    }

    .module3 {
        padding-bottom: 50px;
        background: #fff;

        .navBar_box {
            margin-top: 40px;
        }

        .slideshow {
            margin-top: 32px;

            ::v-deep.el-car-item {
                width: 100%;
                display: flex;
            }

            ::v-deep.el-carousel {
                .el-carousel__container {
                    height: 590px;
                }
            }

            ::v-deep.el-carousel__indicators {
                padding-top: 30px;

                .el-carousel__button {
                    width: 10px;
                    height: 10px;
                    opacity: 1;
                    border-radius: 50%;
                    background: #5b5b5b;
                }

                .el-carousel__indicator.is-active button {
                    width: 10px;
                    height: 10px;
                    background: #e62129;
                    border-radius: 50%;
                }
            }

            ::v-deep .el-carousel__arrow {
                display: none !important;
            }

            .carouselData:nth-child(3n + 1) {
                margin-left: 0px !important;
            }

            .carouselData:hover {
                .name {
                    background: #e62129 !important;
                    opacity: 1 !important;
                }
            }

            .carouselData {
                width: 378px;
                height: 258px;
                margin-left: 31px;
                position: relative;

                img {
                    width: 378px;
                    height: 258px;
                }

                .name {
                    width: 378px;
                    height: 44px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #fff;
                    margin-top: 10px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    background: #000;
                    opacity: 0.7;
                }
            }
        }
    }

    .module4 {
        background: #f4f5f5;
    }

    .module5 {
        .product_main {
            margin-top: 60px;
            padding-bottom: 98px;

            .product_fl {
                width: 400px;
                height: 600px;
                background: url('../assets/img/homes/product.png') no-repeat;
                background-size: 100% 100%;
                position: relative;

                .environmental {
                    width: 474px;
                    height: 114px;
                    background: #e62129;
                    font-size: 20px;
                    font-family: Microsoft YaHei;
                    color: #ffffff;
                    line-height: 22px;
                    padding-top: 33px;
                    padding-left: 44px;
                    position: absolute;
                    top: 30px;
                    left: 30px;
                    z-index: 2;

                    .protective {
                        font-weight: bold;
                    }

                    .green_environmental {
                        margin-top: 16px;
                    }
                }

                .classification::-webkit-scrollbar {
                    display: none;
                }

                .classification {
                    width: 100%;
                    height: 555px;
                    overflow: hidden;
                    overflow-y: scroll;
                    padding-left: 93px;
                    box-sizing: border-box;
                    padding-top: 190px;

                    .classification_list {
                        height: 30px;
                        position: relative;

                        .name {
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                        }

                        .line {
                            width: 27px;
                            height: 1px;
                            background: #333333;
                            position: absolute;
                            top: 10px;
                            left: -35px;
                        }
                    }
                }
            }

            .product_fr {
                width: 800px;
                height: 600px;
                position: relative;

                .product_img {
                    width: 800px;
                    height: 600px;
                }

                .up_down {
                    width: 154px;
                    height: 52px;
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    margin-left: -77px;
                    z-index: 2;
                    padding: 0px 20px;
                    box-sizing: border-box;

                    .mask_layer {
                        width: 154px;
                        height: 52px;
                        opacity: 0.2;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        z-index: 2;
                        background: black;
                    }

                    .cont {
                        width: 154px;
                        height: 52px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 3;
                        padding: 0px 20px;
                        box-sizing: border-box;
                    }

                    img {
                        width: 6px;
                        height: 12px;
                        margin-top: 6px;
                    }

                    .name {
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                    }
                }
            }
        }
    }

    .module6 {
        height: 658px;
        background: #f4f5f5;

        .characteristic {
            margin-top: 40px;

            .characteristic_list:nth-child(3n + 1) {
                margin-left: 0 !important;
            }

            .characteristic_list {
                width: 378px;
                height: 188px;
                background: #ffffff;
                box-shadow: 0px 1px 5px 0px rgba(64, 64, 64, 0.25);
                padding-top: 47px;
                padding-left: 24px;
                box-sizing: border-box;
                margin-left: 32px;
                margin-bottom: 20px;

                .icon_img {
                    width: 88px;
                    height: 88px;
                }

                .icon_n {
                    display: block;
                }

                .icon_y {
                    display: none;
                }

                .info {
                    margin-left: 20px;
                    padding-top: 6px;

                    .title_name {
                        font-size: 20px;
                        font-weight: bold;
                        color: #333333;
                        line-height: 20px;
                    }

                    .details {
                        width: 215px;
                        height: 58px;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #666666;
                        line-height: 20px;
                        margin-top: 11px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                }
            }

            .characteristic_list:hover {
                background: #e52e36 !important;

                .icon_n {
                    display: none;
                }

                .icon_y {
                    display: block;
                }

                .title_name {
                    color: #fff !important;
                }

                .details {
                    color: #fff !important;
                }
            }
        }
    }

    .module7 {
        background: #fff;
        padding-bottom: 100px;

        .advantage {
            margin-top: 42px;

            .advantage_List:hover {
                .float_content {
                    display: block;
                }
            }

            .advantage_List:nth-child(1) {
                margin-left: 0px !important;
            }

            .advantage_List {
                width: 280px;
                height: 420px;
                box-shadow: 0px 1px 5px 0px rgba(64, 64, 64, 0.25);
                padding: 5px 5px;
                position: relative;
                margin-left: 26px;

                .icon_img {
                    width: 270px;
                    height: 267px;
                }

                .name {
                    font-size: 20px;
                    font-weight: bold;
                    color: #333333;
                    margin-top: 55px;
                }

                .line {
                    width: 53px;
                    height: 2px;
                    background: #e62129;
                    margin: 0 auto;
                    margin-top: 25px;
                }

                .float_content {
                    width: 280px;
                    height: 420px;
                    background: #e62129;
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: none;

                    .float_content_name {
                        font-size: 20px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #ffffff;
                        line-height: 24px;
                        margin-top: 102px;
                    }

                    .float_content_line {
                        width: 53px;
                        height: 2px;
                        background: #ffffff;
                        margin: 0 auto;
                        margin-top: 25px;
                    }

                    .float_content_details {
                        width: 160px;
                        height: 64px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #ffffff;
                        line-height: 24px;
                        margin: 0 auto;
                        margin-top: 30px;
                        text-align: center;
                    }
                }
            }
        }
    }

    .module8 {
        height: 860px;
        background: #f4f5f5;

        .content {
            width: 1400px;
        }

        .el_carousel_box {
            margin-top: 40px;

            .el_carousel_cont {
                width: 1200px;

                img {
                    width: 100%;
                }
            }

            ::v-deep.el-carousel__container {
                height: 586px;
            }

            ::v-deep.el-carousel {
                .el-carousel__item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .el-carousel__container {
                    .el-carousel__arrow {
                        width: 60px;
                        height: 60px;
                        font-size: 24px;
                        background: rgba(51, 51, 51, 0.5);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            ::v-deep.el-carousel__indicators {
                display: none;
            }
        }
    }

    .module9 {
        width: 100%;
        height: 548px;
        background: url('../assets/img/serve/bg.png') no-repeat;
        background-size: 100% 100%;

        .title {
            font-size: 32px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
            padding-top: 79px;
            line-height: 32px;
            text-align: center;
        }

        .formData {
            margin-top: 40px;

            .list_message {
                margin-bottom: 16px;

                .list {
                    width: 293px;
                    height: 46px;
                    background: #f5f5f5;
                    margin-right: 15px;
                    box-sizing: border-box;

                    input {
                        width: 278px;
                        height: 42px;
                        padding-left: 15px;
                        font-size: 16px;
                    }

                    ::v-deep.el-input__inner {
                        width: 278px;
                        height: 42px;
                        padding-top: 8px;
                        background: #f5f5f5 !important;
                        border: none;
                    }

                    ::v-deep.el-input__suffix {
                        top: 3px;
                    }
                }
            }

            textarea {
                width: 603px;
                background: #f5f5f5;
                margin-left: -15px;
                font-size: 16px;
                padding: 15px;
                box-sizing: border-box;
            }

            .btn {
                width: 603px;
                height: 50px;
                background: #e62129;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                margin-top: 15px;
                margin-left: -15px;
            }
        }
    }
}
</style>